<template>
  <main class="bg-gray-200">
    <section class="section-header pb-9 pb-lg-12 mb-4 mb-lg-6">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center">
            <h1 class="display-2 mb-3">Terms of Service</h1>
            <p class="mb-0">Updated January 19th, 2022</p>
          </div>
        </div>
      </div>
      <div class="pattern bottom"></div>
    </section>
    <section class="section section-lg pt-0">
      <div class="container mt-n7 mt-lg-n12 z-2">
        <div class="row justify-content-center">
          <div class="col">
            <div class="card border-gray-300 p-4 p-md-5">
              <h2 class="mb-3">Use of the API and Documentation</h2>
              <p>
                a) CHART-IMG API keys can only be obtained through the CHART-IMG
                website. These keys are confidential and should not be shared
                with third parties. API keys can be revoked at any time without
                your consent.
              </p>
              <p>
                b) Subject to your compliance with the terms and conditions, you
                may use the CHART-IMG API only in accordance with these API
                Terms of Service and the API Documentation.
              </p>
              <p>
                c) The number of calls you or your application can make to the
                CHART-IMG API is limited by the plan you have selected.
                CHART-IMG reserves the right to change rate limits on the
                various plans. Repeated and deliberate violation of the rate
                limit may lead to permanent banning from the CHART-IMG API.
              </p>
              <p>
                d) We reserve the right to change the CHART-IMG API and
                documentation without notice. You acknowledge and understand
                that these changes may require you to make substantial changes
                to your application at your own cost and expense. It is your
                responsibility to regularly review our documentation and API
                Terms of Service.
              </p>
              <p>
                e) We reserve the right, at our sole discretion, to suspend or
                revoke your access to the CHART API, at any time and for any
                reason, including for: (i) failure to pay for the services, (ii)
                violation of the Terms and Conditions listed on this page; (iii)
                your use of the API contrary to the documentation; (iv) for
                scheduled maintenance; or (v) to address any security concerns.
              </p>
              <h2 class="mt-5 mb-3">Use Restrictions</h2>
              <p>
                Users accessing the CHART API in any way not expressly permitted
                under these Terms of Service or the documentation will not:
              </p>
              <p>
                a) modify, decompile, reverse engineer, or otherwise alter the
                CHART-IMG API;
              </p>
              <p>
                b) use the CHART-IMG API in any manner that may threaten the
                security or functionality of the CHART-IMG API, our services or
                our other clients;
              </p>
              <h2 class="mt-5 mb-3">Data</h2>
              <p>
                a) Any personal data we require or collect will be used for
                internal purposes only and is never sold or shared with anyone
                outside of CHART-IMG, unless we disclose information in response
                to a law enforcement request.
              </p>
              <p>
                b) Any data provided to you through our API might be subject to
                change. We reserve the right to correct entries into our
                database and it is the user’s responsibility to retrieve the new
                data and to verify transactions. We do not guarantee that any
                data provided by or API is correct and we do not accept any
                responsibility or liability for any damages caused by incorrect
                data.
              </p>
              <p>
                c) No guarantees are made about the correctness of supplied
                data. Any attribution data is an approximation based on research
                done by CHART-IMG and is not regarded as absolute.
              </p>

              <h2 class="mt-5 mb-3">Monitoring Usage of API</h2>
              <p>
                You acknowledge and agree that we may monitor your use of the
                CHART-IMG API and that you will not block or otherwise interfere
                with our monitoring.
              </p>
              <h2 class="mt-5 mb-3">Communications Not Confidential</h2>
              <p>
                Your use of the API requires the transmission of data and
                information over the Internet and public networks. Accordingly,
                we do not, and cannot, guarantee the confidentiality, security
                or reliability of any communications made by you or your
                applications through the CHART-IMG API.
              </p>
              <h2 class="mt-5 mb-3">Ownership and Intellectual Property</h2>
              <p>
                All rights, title and interest in and to the CHART-IMG API, the
                documentation, statistical information, data and all other
                materials provided, and any update, adaptation, translation,
                customization or derivative work will remain with CHART-IMG or
                our third party suppliers. The CHART-IMG API, Documentation,
                Data, and all materials we provide are licensed and not sold to
                you. All rights not expressly granted to you in these API Terms
                of Service are reserved. Commercial use of the CHART-IMG name
                and/or logo is prohibited unless with explicit permission.
                Intellectual property rights lie with the provider of the data
                and/or documents that may not be sold, copied or made public
                without explicit written consent by CHART-IMG. The provided data
                and/or documents may only be used for the purpose of being made
                available.
              </p>

              <h2 class="mt-5 mb-3">License</h2>
              <p>
                CHART-IMG grants personal and free users a limited, personal,
                non-transferable license to use the content provided by this
                service exclusively for personal use. Users agree not to use the
                CHART-IMG service for any commercial use. Content provided by
                CHART-IMG may not be redistributed, sold, altered, published, or
                otherwise used for commercial gain without the consent of
                CHART-IMG. This includes (but is not limited to) using the data
                provided by CHART-IMG in websites or other commercial
                activities. Enterprise Plan users are granted a license to use
                the content provided in programs or apps. Resale of the content
                provided by the API is prohibited unless explicitly approved by
                CHART-IMG.
              </p>

              <h2 class="mt-5 mb-3">Subscriptions</h2>
              <p>
                Subscriptions are paid in advance every month around the first
                payment and are automatically renewed each month. Personal API
                and Personal subscriptions can be cancelled at any time and
                access to them is revoked at the end of the period of the last
                payment. No refunds will be made for partial subscription
                periods after guaranteed refund days. Failure to pay will result
                in the suspension of the account until payment has been made.
              </p>

              <h2 class="mt-5 mb-3">Warranties and Indemnities</h2>
              <p>
                a) If any damages or criminal charges result from (i) your
                applications; (ii) your breach of applicable laws or your
                representations or warranties hereunder; or (iii) your use of
                the CHART-IMG API contrary to these API Terms of Service or the
                CHART-IMG API documentation, CHART-IMG and its employees will be
                defended, indemnified and held harmless by you and any financial
                costs resulting will be for your own account.
              </p>
              <p>
                b) There is no guarantee of uptime or minimal downtime, and
                CHART-IMG is not liable for any damages or lost profits incurred
                throughout the unavailability of the CHART-IMG API service or
                resulting from downtime.
              </p>
              <p>
                c) The service and content are provided as is without warranty
                of any kind, and CHART-IMG is not liable for any damages or lost
                profits caused due to data inaccuracies, absence of data, or
                errors. Any content based on the CHART-IMG API service should
                not be considered information sufficient to establish an
                investment strategy.
              </p>
              <p>
                d) CHART-IMG only provides raw data and makes no financial or
                investment recommendations. CHART-IMG does not recommend or
                suggest any investment strategy of any kind.
              </p>

              <h2 class="mt-5 mb-3">Complaints</h2>
              <p>
                If you have any complaints about the services we provide or wish
                to report the abuse of our services, please send an email to
                support@chart-img.com.
              </p>

              <h2 class="mt-5 mb-3">Changes to Terms of Service</h2>
              <p>
                We reserve the right to change these API Terms of Service at any
                time without notice. It is the user's responsibility to review
                the Terms of Service regularly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
